<template>
  <div class="page"
    style="max-width: 600px; margin: 0 auto;height: 100%;background-size: 100% 100% ; background-image: url(./img/wj07.jpg);"
    v-if="planInfo">

    <div style="padding:15px">

      <div class="tm">
        <div style="padding: 20px;">
          <div class="bolder">
            {{ planInfo.plan_name }}
          </div>
          <div class="wjdesc">
            {{ planInfo.sta_time }} 至 {{ planInfo.end_time }}
          </div>
        </div>


      </div>

      <div class="tm">
        <div class="wjitem flexBetween" @click="goIvst(item)" v-for="(item, idx) in tempList">
          <div class="wjtitle">{{ item.ivst_title }}</div>

          <div class="wjdesc flexBetween">
            <!--						2021-01-01 至 2022-01-01-->
            <span class="wjstatus" v-if="item.joined" style="color: #009900;">已参与</span>
            <span class="wjstatus" style="color: orangered;" v-else>未参与</span>

            <div class="righticon">
              <img :src="'./img/right.png'">
            </div>
          </div>

        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      plan_id: 0,
      ivst_id: 0,
      planInfo: null,
      IvstInfo: null,
      tempList: [],
      color: ["1fa4fb", "3e6bff", "139efc", "149efc", "38aaf5", "2ea7f9", "3f6cfe", "109ffe", "4375ff"],
      tm0List: [],
      ckeckedXX: 0,
      step: 0,
      joined: false
    }
  },
  mounted() {
    if (this.$route.query.chs) {
      if (sessionStorage.getItem("ivst_stu_id")) {
        if (this.$route.query.pid) {
          this.plan_id = this.$route.query.pid
          this.getPlanInfo()
        }
      } else {
        this.$router.push("/ivstchs?pmid="+this.$route.query.pmid+"&pid="+this.$route.query.pid)
      }
    } else {
      if (this.$route.query.pid) {
        this.plan_id = this.$route.query.pid
        this.getPlanInfo()
      }
    }

  },
  methods: {
    getPlanInfo() {
      this.$http.post("/api/ivst_plan", {
        plan_id: this.plan_id,
        uid: sessionStorage.getItem("ivst_stu_id"),
        pmid: sessionStorage.getItem("ivst_pmid")||this.$route.query.pmid,
        utype: 0,
      }).then(res => {
        res.data.stime = new Date(res.data.sta_time.replace(/-/g, '/')).getTime()
        res.data.etime = new Date(res.data.end_time.replace(/-/g, '/')).getTime()
        if (res.data.now < res.data.stime) {
          alert("问卷未开始~")
          return

        } else if (res.data.now >= res.data.etime) {
          alert("问卷已结束~")
          return
        }



        if (res.data.temp && res.data.temp.length > 0) {
          this.planInfo = res.data;
          this.tempList = res.data.temp
          document.title = this.planInfo.plan_name
          if (this.tempList.length == 1) {


            if (this.tempList[0].firstTM && this.tempList[0].firstTM.tm_title.indexOf("您现在的情况") >= 0) {
              this.$router.push("/quxiang?pid=" + this.plan_id + "&id=" + this.tempList[0].id + "&pmid=" + this.tempList[0].pmid)
            } else {
              if(this.planInfo.open){
                sessionStorage.setItem("ivst_stu_id",parseInt(100000000+Math.random()*100000000))
              }
              this.$router.push("/h5_ivst?pid=" + this.plan_id + "&id=" + this.tempList[0].id + "&pmid=" + this.tempList[0].pmid)
            }


            // if (sessionStorage.getItem("back")) {
            //   sessionStorage.setItem("back", 1)

            // }else{

            // }

          }
          //this.getIvst()
        } else {
          alert("您无需填写该问卷~")
        }

      })
    },
    getIvst() {
      this.$http.post("api/h5_ivst_one", {
        temp_id: this.ivst_id,
        pmid: this.plan.pmid,
        uid: sessionStorage.getItem("ivst_stu_id"),
        utype: 0,
      }).then(res => {
        if (res.data.answers && res.data.answers.length > 0) {
          this.joined = true
        }
        this.IvstInfo = res.data
        if (this.IvstInfo && this.IvstInfo.tm_title.indexOf("您现在的情况") >= 0) {
          this.$router.push("/quxiang?pid=" + this.plan_id + "&id=" + this.IvstInfo.id)
        } else {
          this.$router.push("/h5_ivst?pid=" + this.plan_id + "&id=" + this.IvstInfo.id)
        }
      })
    },

    goNext(step) {
      let _this = this
      if (this.joined) {
        alert("您已参与过，请勿重复参与！")
      } else {

        this.step = step
        this.$nextTick(() => {
          $(window).scrollTop(0)
          if (step == 1) {
            if (_this.ckeckedXX) {

              localStorage.setItem("ckeckedXX", _this.ckeckedXX)
              _this.$router.push("/ivst?plan=" + _this.$route.params.id + "&id=" + _this.ivst_id)
            } else {
              _this.$message.error("请先选择当前状态")
              _this.step = 0
              return
            }
          }
        })


      }

    },
    goIvst(row) {

      this.$router.push("/h5_ivst?pid=" + this.plan_id + "&id=" + row.id)
    },
    showToast(msg) {
      $("#toast").text(msg).show().animate({
        opacity: 1
      }, 200, "linear", function () {
        setTimeout(() => {
          $("#toast").animate({
            opacity: 0
          }, "linear", 3000, function () {
            $("#toast").hide()
          })
        }, 2000)
      })
    },


  },
}
</script>

<style>
.tm {
  border: 3px solid #0f9fff;
  margin-bottom: 20px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 3px 1px 1px #2226bc;

}

.stitem {
  width: 45%;
  display: inline-block;
  vertical-align: top;
  margin-bottom: 10px;
  border-radius: 4px;
  box-sizing: border-box;
  border: 2px solid #1fa4fb;
  background-color: #1fa4fb;
}

.stitemckd {
  border: 2px solid #f9b379;
}

.stitem:nth-child(2n+1) {
  margin-right: 10%;
}

.bolder {
  font-weight: bolder;
}

.wjitem {
  border-bottom: 1px solid #eee;
  font-size: 14px;
  padding: 10px 15px;
  position: relative;
  border-radius: 10px;
}

.wjdesc {
  font-size: 12px;
  padding: 10px 0;
}

.wjstatus {
  /*float: right;*/
  margin-right: 10px;
  vertical-align: middle;
}

.wjtitle {
  margin-right: 30px;
}

.wjitem:active {
  background-color: #eee;
}

.righticon {
  width: 10px;
  height: 15px;
  /*position: absolute;*/
  /*top: 25px;*/
  /*right: 10px;*/
}

.righticon img {
  width: 100%;
  height: 100%;
}

.wjitem:nth-child(1) {
  border-top: 1px solid #eee;
}
</style>
